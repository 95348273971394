.zone {
  display: grid;
  gap: var(--ads-v2-spaces-4);
  width: 100%;
  box-sizing: border-box;

  &[data-layout="double_column"] {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  &[data-layout="single_column"] {
    grid-template-columns: 1fr;
  }

  & > div:empty {
    display: none;
  }

  /*
   This section can be removed once the condition below each is resolved
  */

  /*  1. Margin is removed from FieldWrapper component in FormRender file */

  & :global(.uqi-form-wrapper) {
    margin: 0;
  }

  /* reset ads select min width */

  & :global(.ads-v2-select > .rc-select-selector) {
    min-width: unset;
  }

  /* Remove this once the config in DB is updated to use Section and Zone (Twilio, Airtable) */

  & :global(.ar-form-info-text) {
    max-width: unset;
  }

  /* Removable section ends here */
}
